export function maxBy<T>(arr: T[], fn: (item: T) => number): T | undefined {
  let maxItem: T | undefined = undefined;
  let max = -Infinity;
  for (const item of arr) {
    const value = fn(item);
    if (value > max) {
      max = value;
      maxItem = item;
    }
  }
  return maxItem;
}

export function maxOf<T>(arr: T[], fn: (item: T) => number): number | undefined {
  let max = undefined;
  for (const item of arr) {
    const value = fn(item);
    if (value > max || max === undefined) {
      max = value;
    }
  }
  return max;
}

export function max(arr: number[]): number | undefined {
  return maxBy(arr, (x) => x);
}
