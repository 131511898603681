import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AllowedOrganisation } from '../requests/models/AllowedOrganisation';
import { AssignPersonRequest } from '../requests/models/AssignPersonRequest';
import { AssignUserRequest } from '../requests/models/AssignUserRequest';
import { AuditTrailResponse } from '../requests/models/AuditTrailResponse';
import { AuthenticationAuditTrailResponse } from '../requests/models/AuthenticationAuditTrailResponse';
import { AuthenticationFailureEvent } from '../requests/models/AuthenticationFailureEvent';
import { BackgroundTaskResponse } from '../requests/models/BackgroundTaskResponse';
import { BackgroundTaskStatus } from '../requests/models/BackgroundTaskStatus';
import { BackgroundTaskStatusResponse } from '../requests/models/BackgroundTaskStatusResponse';
import { Bandwidth } from '../requests/models/Bandwidth';
import { BandwidthOption } from '../requests/models/BandwidthOption';
import { Berth } from '../requests/models/Berth';
import { BerthAssignment } from '../requests/models/BerthAssignment';
import { BerthAssignmentRequest } from '../requests/models/BerthAssignmentRequest';
import { BerthAssignmentUploadStatusResponse } from '../requests/models/BerthAssignmentUploadStatusResponse';
import { BerthDmaCaseAssignmentResponse } from '../requests/models/BerthDmaCaseAssignmentResponse';
import { BerthDmaCaseSafetyResponse } from '../requests/models/BerthDmaCaseSafetyResponse';
import { BerthDmaCaseSafetyWarning } from '../requests/models/BerthDmaCaseSafetyWarning';
import { BerthDmaCasesAssignmentRequest } from '../requests/models/BerthDmaCasesAssignmentRequest';
import { BerthFeatures } from '../requests/models/BerthFeatures';
import { BerthRequest } from '../requests/models/BerthRequest';
import { BerthResponse } from '../requests/models/BerthResponse';
import { BerthSafetyAuditTrailResponse } from '../requests/models/BerthSafetyAuditTrailResponse';
import { BerthSafetyEventSummary } from '../requests/models/BerthSafetyEventSummary';
import { BerthSafetyResponse } from '../requests/models/BerthSafetyResponse';
import { BerthSafetyWarningSource } from '../requests/models/BerthSafetyWarningSource';
import { BerthType } from '../requests/models/BerthType';
import { BerthUpdateRequest } from '../requests/models/BerthUpdateRequest';
import { BerthsResponse } from '../requests/models/BerthsResponse';
import { BollardInformation } from '../requests/models/BollardInformation';
import { BulkWindAmplificationFactorsPerDmaCasesUpdateRequest } from '../requests/models/BulkWindAmplificationFactorsPerDmaCasesUpdateRequest';
import { CommandAuditTrailEvent } from '../requests/models/CommandAuditTrailEvent';
import { CommandAuditTrailResponse } from '../requests/models/CommandAuditTrailResponse';
import { Coordinates } from '../requests/models/Coordinates';
import { CustomerParameters } from '../requests/models/CustomerParameters';
import { CustomerType } from '../requests/models/CustomerType';
import { DmaCase } from '../requests/models/DmaCase';
import { DmaCaseBaseOfSimulation } from '../requests/models/DmaCaseBaseOfSimulation';
import { DmaCaseBerthCharacteristics } from '../requests/models/DmaCaseBerthCharacteristics';
import { DmaCaseDefinition } from '../requests/models/DmaCaseDefinition';
import { DmaCaseDefinitions } from '../requests/models/DmaCaseDefinitions';
import { DmaCaseFenderCharacteristics } from '../requests/models/DmaCaseFenderCharacteristics';
import { DmaCaseLineMaterialCharacteristics } from '../requests/models/DmaCaseLineMaterialCharacteristics';
import { DmaCaseMatrixUploadStatusResponse } from '../requests/models/DmaCaseMatrixUploadStatusResponse';
import { DmaCaseMooringConfigurationUploadResponse } from '../requests/models/DmaCaseMooringConfigurationUploadResponse';
import { DmaCaseMooringLineCharacteristics } from '../requests/models/DmaCaseMooringLineCharacteristics';
import { DmaCaseType } from '../requests/models/DmaCaseType';
import { DmaCaseVerificationResponse } from '../requests/models/DmaCaseVerificationResponse';
import { DmaCaseVessel } from '../requests/models/DmaCaseVessel';
import { DmaCaseVesselCharacteristics } from '../requests/models/DmaCaseVesselCharacteristics';
import { DmaCaseVesselLoadBasedCharacteristics } from '../requests/models/DmaCaseVesselLoadBasedCharacteristics';
import { DmaMatrixMetadataRequest } from '../requests/models/DmaMatrixMetadataRequest';
import { DomainEvent } from '../requests/models/DomainEvent';
import { EnvironmentalConditionPriority } from '../requests/models/EnvironmentalConditionPriority';
import { ErrorResponse } from '../requests/models/ErrorResponse';
import { EventProcessingStatus } from '../requests/models/EventProcessingStatus';
import { EventSummary } from '../requests/models/EventSummary';
import { EventTrackerSegmentStatus } from '../requests/models/EventTrackerSegmentStatus';
import { EventTrackerStatus } from '../requests/models/EventTrackerStatus';
import { FeedbackRequest } from '../requests/models/FeedbackRequest';
import { FindPersonRequest } from '../requests/models/FindPersonRequest';
import { HistoricMetoceanDataRequest } from '../requests/models/HistoricMetoceanDataRequest';
import { HistoricMetoceanUploadRequest } from '../requests/models/HistoricMetoceanUploadRequest';
import { LineMaterial } from '../requests/models/LineMaterial';
import { LoadingCondition } from '../requests/models/LoadingCondition';
import { MatrixRowSelectionPriority } from '../requests/models/MatrixRowSelectionPriority';
import { MatrixRowSelectionRequest } from '../requests/models/MatrixRowSelectionRequest';
import { MatrixRowSelectionResponse } from '../requests/models/MatrixRowSelectionResponse';
import { MetoceanForecast } from '../requests/models/MetoceanForecast';
import { MetoceanStation } from '../requests/models/MetoceanStation';
import { MetoceanStationAssignmentRequest } from '../requests/models/MetoceanStationAssignmentRequest';
import { MetoceanStationStatus } from '../requests/models/MetoceanStationStatus';
import { MetoceanWaveForecast } from '../requests/models/MetoceanWaveForecast';
import { MetoceanWindForecast } from '../requests/models/MetoceanWindForecast';
import { MooredVessel } from '../requests/models/MooredVessel';
import { MooringDetectionResponse } from '../requests/models/MooringDetectionResponse';
import { Organisation } from '../requests/models/Organisation';
import { OrganisationLogoResponse } from '../requests/models/OrganisationLogoResponse';
import { OrganisationRequest } from '../requests/models/OrganisationRequest';
import { OrganisationResponse } from '../requests/models/OrganisationResponse';
import { OrganisationSearchRequest } from '../requests/models/OrganisationSearchRequest';
import { OrganisationType } from '../requests/models/OrganisationType';
import { PeopleOverviewResponse } from '../requests/models/PeopleOverviewResponse';
import { Person } from '../requests/models/Person';
import { ProductFeature } from '../requests/models/ProductFeature';
import { ProductLicense } from '../requests/models/ProductLicense';
import { RefreshMetoceanForecastsRequest } from '../requests/models/RefreshMetoceanForecastsRequest';
import { RegisterPersonRequest } from '../requests/models/RegisterPersonRequest';
import { SafetyPrediction } from '../requests/models/SafetyPrediction';
import { SafetyPredictionGenerationRequest } from '../requests/models/SafetyPredictionGenerationRequest';
import { SafetyPredictionTrigger } from '../requests/models/SafetyPredictionTrigger';
import { SafetyPredictionType } from '../requests/models/SafetyPredictionType';
import { SimpleBerthAssignment } from '../requests/models/SimpleBerthAssignment';
import { SmartSocietyPerson } from '../requests/models/SmartSocietyPerson';
import { ThresholdType } from '../requests/models/ThresholdType';
import { ToggleDmaCases } from '../requests/models/ToggleDmaCases';
import { UpdatePersonPreferencesRequest } from '../requests/models/UpdatePersonPreferencesRequest';
import { UserPreferences } from '../requests/models/UserPreferences';
import { VesselClassDefinition } from '../requests/models/VesselClassDefinition';
import { VesselClearanceSupportedVesselType } from '../requests/models/VesselClearanceSupportedVesselType';
import { VesselDisposition } from '../requests/models/VesselDisposition';
import { VesselType } from '../requests/models/VesselType';
import { WindAmplificationFactorUpdateRequest } from '../requests/models/WindAmplificationFactorUpdateRequest';
import { WindAmplificationFactorsPerDmaCaseUpdateRequest } from '../requests/models/WindAmplificationFactorsPerDmaCaseUpdateRequest';
import { AuditTrailService } from '../requests/services/AuditTrailService';
import { BehindTheScenesService } from '../requests/services/BehindTheScenesService';
import { BerthManagementService } from '../requests/services/BerthManagementService';
import { DmaManagementService } from '../requests/services/DmaManagementService';
import { MetoceanStationManagementService } from '../requests/services/MetoceanStationManagementService';
import { OrganisationManagementService } from '../requests/services/OrganisationManagementService';
import { PeopleManagementService } from '../requests/services/PeopleManagementService';
import { SystemService } from '../requests/services/SystemService';
import { VesselManagementService } from '../requests/services/VesselManagementService';

export const useVesselManagementServiceGetMooredVesselsKey = 'VesselManagementServiceGetMooredVessels';
export const useVesselManagementServiceGetMooredVessels = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof VesselManagementService.getMooredVessels>>,
      unknown,
      Awaited<ReturnType<typeof VesselManagementService.getMooredVessels>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useVesselManagementServiceGetMooredVesselsKey, ...(queryKey ?? [])], () => VesselManagementService.getMooredVessels(), options);
export const useVesselManagementServiceUnmoorVessel = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof VesselManagementService.unmoorVessel>>,
      unknown,
      {
        visitId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ visitId }) => VesselManagementService.unmoorVessel(visitId), options);
export const useSystemServiceGetEventProcessingStatusKey = 'SystemServiceGetEventProcessingStatus';
export const useSystemServiceGetEventProcessingStatus = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof SystemService.getEventProcessingStatus>>,
      unknown,
      Awaited<ReturnType<typeof SystemService.getEventProcessingStatus>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useSystemServiceGetEventProcessingStatusKey, ...(queryKey ?? [])], () => SystemService.getEventProcessingStatus(), options);
export const usePeopleManagementServiceRegisterPerson = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof PeopleManagementService.registerPerson>>,
      unknown,
      {
        requestBody: RegisterPersonRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => PeopleManagementService.registerPerson(requestBody), options);
export const usePeopleManagementServiceListPeopleKey = 'PeopleManagementServiceListPeople';
export const usePeopleManagementServiceListPeople = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof PeopleManagementService.listPeople>>,
      unknown,
      Awaited<ReturnType<typeof PeopleManagementService.listPeople>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([usePeopleManagementServiceListPeopleKey, ...(queryKey ?? [])], () => PeopleManagementService.listPeople(), options);
export const usePeopleManagementServiceAssignPerson = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof PeopleManagementService.assignPerson>>,
      unknown,
      {
        requestBody: AssignPersonRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => PeopleManagementService.assignPerson(requestBody), options);
export const usePeopleManagementServiceSavePreferences = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof PeopleManagementService.savePreferences>>,
      unknown,
      {
        personId: string;
        requestBody: UpdatePersonPreferencesRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ personId, requestBody }) => PeopleManagementService.savePreferences(personId, requestBody), options);
export const usePeopleManagementServiceFindPersonKey = 'PeopleManagementServiceFindPerson';
export const usePeopleManagementServiceFindPerson = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    personId,
    email,
  }: {
    personId?: string;
    email?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof PeopleManagementService.findPerson>>,
      unknown,
      Awaited<ReturnType<typeof PeopleManagementService.findPerson>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [usePeopleManagementServiceFindPersonKey, ...(queryKey ?? [{ personId, email }])],
    () => PeopleManagementService.findPerson(personId, email),
    options
  );
export const usePeopleManagementServiceRefreshPeopleKey = 'PeopleManagementServiceRefreshPeople';
export const usePeopleManagementServiceRefreshPeople = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    personId,
  }: {
    personId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof PeopleManagementService.refreshPeople>>,
      unknown,
      Awaited<ReturnType<typeof PeopleManagementService.refreshPeople>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [usePeopleManagementServiceRefreshPeopleKey, ...(queryKey ?? [{ personId }])],
    () => PeopleManagementService.refreshPeople(personId),
    options
  );
export const useOrganisationManagementServiceListOrganisationsKey = 'OrganisationManagementServiceListOrganisations';
export const useOrganisationManagementServiceListOrganisations = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.listOrganisations>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.listOrganisations>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceListOrganisationsKey, ...(queryKey ?? [])],
    () => OrganisationManagementService.listOrganisations(),
    options
  );
export const useOrganisationManagementServiceSaveOrganisation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.saveOrganisation>>,
      unknown,
      {
        requestBody: OrganisationRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => OrganisationManagementService.saveOrganisation(requestBody), options);
export const useOrganisationManagementServiceGetOrganisationKey = 'OrganisationManagementServiceGetOrganisation';
export const useOrganisationManagementServiceGetOrganisation = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.getOrganisation>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.getOrganisation>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceGetOrganisationKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.getOrganisation(organisationId),
    options
  );
export const useOrganisationManagementServiceDeleteOrganisationLogo = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.deleteOrganisationLogo>>,
      unknown,
      {
        organisationId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId }) => OrganisationManagementService.deleteOrganisationLogo(organisationId), options);
export const useOrganisationManagementServiceUploadOrganisationLogo = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.uploadOrganisationLogo>>,
      unknown,
      {
        organisationId: string;
        formData?: {
          file?: Blob;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, formData }) => OrganisationManagementService.uploadOrganisationLogo(organisationId, formData), options);
export const useOrganisationManagementServiceSynchroniseSmartSocietyLicenseKey = 'OrganisationManagementServiceSynchroniseSmartSocietyLicense';
export const useOrganisationManagementServiceSynchroniseSmartSocietyLicense = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.synchroniseSmartSocietyLicense>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.synchroniseSmartSocietyLicense>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceSynchroniseSmartSocietyLicenseKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.synchroniseSmartSocietyLicense(organisationId),
    options
  );
export const useOrganisationManagementServiceListOrganisationUsersKey = 'OrganisationManagementServiceListOrganisationUsers';
export const useOrganisationManagementServiceListOrganisationUsers = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.listOrganisationUsers>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.listOrganisationUsers>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceListOrganisationUsersKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.listOrganisationUsers(organisationId),
    options
  );
export const useOrganisationManagementServiceAssignUser = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.assignUser>>,
      unknown,
      {
        organisationId: string;
        requestBody: AssignUserRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, requestBody }) => OrganisationManagementService.assignUser(organisationId, requestBody), options);
export const useOrganisationManagementServiceUnassignUser = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.unassignUser>>,
      unknown,
      {
        organisationId: string;
        personId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, personId }) => OrganisationManagementService.unassignUser(organisationId, personId), options);
export const useOrganisationManagementServiceFindOrganisations = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.findOrganisations>>,
      unknown,
      {
        requestBody: OrganisationSearchRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => OrganisationManagementService.findOrganisations(requestBody), options);
export const useOrganisationManagementServicePurchaseDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.purchaseDmaCases>>,
      unknown,
      {
        organisationId: string;
        requestBody: ToggleDmaCases;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, requestBody }) => OrganisationManagementService.purchaseDmaCases(organisationId, requestBody), options);
export const useOrganisationManagementServiceListPurchasedDmaCasesKey = 'OrganisationManagementServiceListPurchasedDmaCases';
export const useOrganisationManagementServiceListPurchasedDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.listPurchasedDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.listPurchasedDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceListPurchasedDmaCasesKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.listPurchasedDmaCases(organisationId),
    options
  );
export const useOrganisationManagementServiceEvaluateDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.evaluateDmaCases>>,
      unknown,
      {
        organisationId: string;
        requestBody: ToggleDmaCases;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, requestBody }) => OrganisationManagementService.evaluateDmaCases(organisationId, requestBody), options);
export const useOrganisationManagementServiceListEvaluationDmaCasesKey = 'OrganisationManagementServiceListEvaluationDmaCases';
export const useOrganisationManagementServiceListEvaluationDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.listEvaluationDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.listEvaluationDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceListEvaluationDmaCasesKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.listEvaluationDmaCases(organisationId),
    options
  );
export const useOrganisationManagementServicePromoteOrganisationToProduction = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.promoteOrganisationToProduction>>,
      unknown,
      {
        organisationId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId }) => OrganisationManagementService.promoteOrganisationToProduction(organisationId), options);
export const useOrganisationManagementServiceLinkOrganisations = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.linkOrganisations>>,
      unknown,
      {
        parentOrganisationId: string;
        organisationId: string;
        licenseId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ parentOrganisationId, organisationId, licenseId }) =>
      OrganisationManagementService.linkOrganisations(parentOrganisationId, organisationId, licenseId),
    options
  );
export const useOrganisationManagementServiceImportOrganisations = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.importOrganisations>>,
      unknown,
      {
        requestBody: Array<OrganisationRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => OrganisationManagementService.importOrganisations(requestBody), options);
export const useOrganisationManagementServiceGetBerthAssignmentsKey = 'OrganisationManagementServiceGetBerthAssignments';
export const useOrganisationManagementServiceGetBerthAssignments = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.getBerthAssignments>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.getBerthAssignments>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceGetBerthAssignmentsKey, ...(queryKey ?? [{ organisationId }])],
    () => OrganisationManagementService.getBerthAssignments(organisationId),
    options
  );
export const useOrganisationManagementServiceUpdateBerthAssignments = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.updateBerthAssignments>>,
      unknown,
      {
        organisationId: string;
        requestBody: BerthUpdateRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, requestBody }) => OrganisationManagementService.updateBerthAssignments(organisationId, requestBody), options);
export const useOrganisationManagementServiceEnableBerthAssignments = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.enableBerthAssignments>>,
      unknown,
      {
        organisationId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId }) => OrganisationManagementService.enableBerthAssignments(organisationId), options);
export const useOrganisationManagementServiceDisableBerthAssignments = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.disableBerthAssignments>>,
      unknown,
      {
        organisationId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId }) => OrganisationManagementService.disableBerthAssignments(organisationId), options);
export const useOrganisationManagementServiceAssignBulkDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.assignBulkDmaCases>>,
      unknown,
      {
        organisationId: string;
        requestBody: Array<BerthDmaCasesAssignmentRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, requestBody }) => OrganisationManagementService.assignBulkDmaCases(organisationId, requestBody), options);
export const useOrganisationManagementServiceGetBerthAssignmentKey = 'OrganisationManagementServiceGetBerthAssignment';
export const useOrganisationManagementServiceGetBerthAssignment = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
    berthId,
  }: {
    organisationId: string;
    berthId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.getBerthAssignment>>,
      unknown,
      Awaited<ReturnType<typeof OrganisationManagementService.getBerthAssignment>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useOrganisationManagementServiceGetBerthAssignmentKey, ...(queryKey ?? [{ organisationId, berthId }])],
    () => OrganisationManagementService.getBerthAssignment(organisationId, berthId),
    options
  );
export const useOrganisationManagementServiceEnableBerthAssignment = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.enableBerthAssignment>>,
      unknown,
      {
        organisationId: string;
        berthId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, berthId }) => OrganisationManagementService.enableBerthAssignment(organisationId, berthId), options);
export const useOrganisationManagementServiceDisableBerthAssignment = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.disableBerthAssignment>>,
      unknown,
      {
        organisationId: string;
        berthId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, berthId }) => OrganisationManagementService.disableBerthAssignment(organisationId, berthId), options);
export const useOrganisationManagementServiceAssignMetoceanStation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.assignMetoceanStation>>,
      unknown,
      {
        organisationId: string;
        berthId: string;
        requestBody?: MetoceanStationAssignmentRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ organisationId, berthId, requestBody }) => OrganisationManagementService.assignMetoceanStation(organisationId, berthId, requestBody),
    options
  );
export const useOrganisationManagementServiceDisableMetoceanStation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.disableMetoceanStation>>,
      unknown,
      {
        organisationId: string;
        berthId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, berthId }) => OrganisationManagementService.disableMetoceanStation(organisationId, berthId), options);
export const useOrganisationManagementServiceAssignDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.assignDmaCases>>,
      unknown,
      {
        organisationId: string;
        berthId: string;
        requestBody: Array<string>;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ organisationId, berthId, requestBody }) => OrganisationManagementService.assignDmaCases(organisationId, berthId, requestBody),
    options
  );
export const useOrganisationManagementServiceUploadWindAmplificationFactors = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.uploadWindAmplificationFactors>>,
      unknown,
      {
        berthId: string;
        organisationId: string;
        dmaCaseId: string;
        requestBody: Array<WindAmplificationFactorUpdateRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ berthId, organisationId, dmaCaseId, requestBody }) =>
      OrganisationManagementService.uploadWindAmplificationFactors(berthId, organisationId, dmaCaseId, requestBody),
    options
  );
export const useOrganisationManagementServiceUploadWindAmplificationFactorsPerDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.uploadWindAmplificationFactorsPerDmaCases>>,
      unknown,
      {
        berthId: string;
        organisationId: string;
        requestBody: Array<WindAmplificationFactorsPerDmaCaseUpdateRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ berthId, organisationId, requestBody }) =>
      OrganisationManagementService.uploadWindAmplificationFactorsPerDmaCases(berthId, organisationId, requestBody),
    options
  );
export const useOrganisationManagementServiceBulkUploadWindAmplificationFactorsPerDmaCases = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof OrganisationManagementService.bulkUploadWindAmplificationFactorsPerDmaCases>>,
      unknown,
      {
        organisationId: string;
        requestBody: Array<BulkWindAmplificationFactorsPerDmaCasesUpdateRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ organisationId, requestBody }) => OrganisationManagementService.bulkUploadWindAmplificationFactorsPerDmaCases(organisationId, requestBody),
    options
  );
export const useMetoceanStationManagementServiceListMetoceanStationsKey = 'MetoceanStationManagementServiceListMetoceanStations';
export const useMetoceanStationManagementServiceListMetoceanStations = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof MetoceanStationManagementService.listMetoceanStations>>,
      unknown,
      Awaited<ReturnType<typeof MetoceanStationManagementService.listMetoceanStations>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useMetoceanStationManagementServiceListMetoceanStationsKey, ...(queryKey ?? [])],
    () => MetoceanStationManagementService.listMetoceanStations(),
    options
  );
export const useMetoceanStationManagementServiceUploadHistoricMetoceanData = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof MetoceanStationManagementService.uploadHistoricMetoceanData>>,
      unknown,
      {
        metoceanProvider: string;
        metoceanStation: string;
        formData: {
          file?: Blob;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMutation(
    ({ metoceanProvider, metoceanStation, formData }) =>
      MetoceanStationManagementService.uploadHistoricMetoceanData(metoceanProvider, metoceanStation, formData),
    options
  );
export const useMetoceanStationManagementServiceGenerateHistoricWarnings = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof MetoceanStationManagementService.generateHistoricWarnings>>,
      unknown,
      {
        requestBody: HistoricMetoceanDataRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => MetoceanStationManagementService.generateHistoricWarnings(requestBody), options);
export const useMetoceanStationManagementServiceRefreshForecasts = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof MetoceanStationManagementService.refreshForecasts>>,
      unknown,
      {
        requestBody?: RefreshMetoceanForecastsRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => MetoceanStationManagementService.refreshForecasts(requestBody), options);
export const useDmaManagementServiceListDmaCasesKey = 'DmaManagementServiceListDmaCases';
export const useDmaManagementServiceListDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaManagementService.listDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof DmaManagementService.listDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useDmaManagementServiceListDmaCasesKey, ...(queryKey ?? [])], () => DmaManagementService.listDmaCases(), options);
export const useDmaManagementServiceUploadDmaCase = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof DmaManagementService.uploadDmaCase>>,
      unknown,
      {
        formData?: {
          file: Array<Blob>;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ formData }) => DmaManagementService.uploadDmaCase(formData), options);
export const useDmaManagementServiceGetDmaCaseUploadStatusKey = 'DmaManagementServiceGetDmaCaseUploadStatus';
export const useDmaManagementServiceGetDmaCaseUploadStatus = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    taskId,
  }: {
    taskId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaManagementService.getDmaCaseUploadStatus>>,
      unknown,
      Awaited<ReturnType<typeof DmaManagementService.getDmaCaseUploadStatus>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useDmaManagementServiceGetDmaCaseUploadStatusKey, ...(queryKey ?? [{ taskId }])],
    () => DmaManagementService.getDmaCaseUploadStatus(taskId),
    options
  );
export const useDmaManagementServiceUploadDmaCaseMooringConfiguration = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof DmaManagementService.uploadDmaCaseMooringConfiguration>>,
      unknown,
      {
        dmaCaseId: string;
        formData?: {
          file: Blob;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ dmaCaseId, formData }) => DmaManagementService.uploadDmaCaseMooringConfiguration(dmaCaseId, formData), options);
export const useDmaManagementServiceValidateDmaCaseMetadata = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof DmaManagementService.validateDmaCaseMetadata>>,
      unknown,
      {
        formData?: {
          file: Blob;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ formData }) => DmaManagementService.validateDmaCaseMetadata(formData), options);
export const useDmaManagementServiceVerifyDmaCasesKey = 'DmaManagementServiceVerifyDmaCases';
export const useDmaManagementServiceVerifyDmaCases = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaManagementService.verifyDmaCases>>,
      unknown,
      Awaited<ReturnType<typeof DmaManagementService.verifyDmaCases>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useDmaManagementServiceVerifyDmaCasesKey, ...(queryKey ?? [])], () => DmaManagementService.verifyDmaCases(), options);
export const useDmaManagementServiceListLibraryDmaCaseDefinitionsKey = 'DmaManagementServiceListLibraryDmaCaseDefinitions';
export const useDmaManagementServiceListLibraryDmaCaseDefinitions = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaManagementService.listLibraryDmaCaseDefinitions>>,
      unknown,
      Awaited<ReturnType<typeof DmaManagementService.listLibraryDmaCaseDefinitions>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useDmaManagementServiceListLibraryDmaCaseDefinitionsKey, ...(queryKey ?? [])],
    () => DmaManagementService.listLibraryDmaCaseDefinitions(),
    options
  );
export const useDmaManagementServiceListSpecificDmaCaseDefinitionsKey = 'DmaManagementServiceListSpecificDmaCaseDefinitions';
export const useDmaManagementServiceListSpecificDmaCaseDefinitions = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof DmaManagementService.listSpecificDmaCaseDefinitions>>,
      unknown,
      Awaited<ReturnType<typeof DmaManagementService.listSpecificDmaCaseDefinitions>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useDmaManagementServiceListSpecificDmaCaseDefinitionsKey, ...(queryKey ?? [])],
    () => DmaManagementService.listSpecificDmaCaseDefinitions(),
    options
  );
export const useBerthManagementServiceDownloadBerthsKey = 'BerthManagementServiceDownloadBerths';
export const useBerthManagementServiceDownloadBerths = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    organisationId,
  }: {
    organisationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof BerthManagementService.downloadBerths>>,
      unknown,
      Awaited<ReturnType<typeof BerthManagementService.downloadBerths>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useBerthManagementServiceDownloadBerthsKey, ...(queryKey ?? [{ organisationId }])],
    () => BerthManagementService.downloadBerths(organisationId),
    options
  );
export const useBerthManagementServiceRegisterBerths = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.registerBerths>>,
      unknown,
      {
        organisationId: string;
        formData?: {
          file: Blob;
        };
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ organisationId, formData }) => BerthManagementService.registerBerths(organisationId, formData), options);
export const useBerthManagementServiceGetBerthFileStatusKey = 'BerthManagementServiceGetBerthFileStatus';
export const useBerthManagementServiceGetBerthFileStatus = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    taskId,
  }: {
    taskId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof BerthManagementService.getBerthFileStatus>>,
      unknown,
      Awaited<ReturnType<typeof BerthManagementService.getBerthFileStatus>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useBerthManagementServiceGetBerthFileStatusKey, ...(queryKey ?? [{ taskId }])],
    () => BerthManagementService.getBerthFileStatus(taskId),
    options
  );
export const useBerthManagementServiceBulkRegisterBerths = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.bulkRegisterBerths>>,
      unknown,
      {
        requestBody: Array<BerthRequest>;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BerthManagementService.bulkRegisterBerths(requestBody), options);
export const useBerthManagementServiceEnableMooringDetection = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.enableMooringDetection>>,
      unknown,
      {
        requestBody: Array<string>;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BerthManagementService.enableMooringDetection(requestBody), options);
export const useBerthManagementServiceDisableMooringDetection = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.disableMooringDetection>>,
      unknown,
      {
        requestBody: Array<string>;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BerthManagementService.disableMooringDetection(requestBody), options);
export const useBerthManagementServiceDisableMooringDetectionForBerth = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.disableMooringDetectionForBerth>>,
      unknown,
      {
        berthId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ berthId }) => BerthManagementService.disableMooringDetectionForBerth(berthId), options);
export const useBerthManagementServiceListBerthsKey = 'BerthManagementServiceListBerths';
export const useBerthManagementServiceListBerths = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof BerthManagementService.listBerths>>,
      unknown,
      Awaited<ReturnType<typeof BerthManagementService.listBerths>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useBerthManagementServiceListBerthsKey, ...(queryKey ?? [{ pageNumber, pageSize }])],
    () => BerthManagementService.listBerths(pageNumber, pageSize),
    options
  );
export const useBerthManagementServiceRegisterBerth = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.registerBerth>>,
      unknown,
      {
        requestBody: BerthRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BerthManagementService.registerBerth(requestBody), options);
export const useBerthManagementServiceListAllAssignedBerthsKey = 'BerthManagementServiceListAllAssignedBerths';
export const useBerthManagementServiceListAllAssignedBerths = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof BerthManagementService.listAllAssignedBerths>>,
      unknown,
      Awaited<ReturnType<typeof BerthManagementService.listAllAssignedBerths>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery([useBerthManagementServiceListAllAssignedBerthsKey, ...(queryKey ?? [])], () => BerthManagementService.listAllAssignedBerths(), options);
export const useBerthManagementServiceGetBerthSafetyWarningsKey = 'BerthManagementServiceGetBerthSafetyWarnings';
export const useBerthManagementServiceGetBerthSafetyWarnings = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof BerthManagementService.getBerthSafetyWarnings>>,
      unknown,
      Awaited<ReturnType<typeof BerthManagementService.getBerthSafetyWarnings>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useBerthManagementServiceGetBerthSafetyWarningsKey, ...(queryKey ?? [{ pageNumber, pageSize }])],
    () => BerthManagementService.getBerthSafetyWarnings(pageNumber, pageSize),
    options
  );
export const useBerthManagementServiceGenerateSafetyPredictions = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BerthManagementService.generateSafetyPredictions>>,
      unknown,
      {
        requestBody?: SafetyPredictionGenerationRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BerthManagementService.generateSafetyPredictions(requestBody), options);
export const useBehindTheScenesServiceSelectMatrixRow = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof BehindTheScenesService.selectMatrixRow>>,
      unknown,
      {
        requestBody: MatrixRowSelectionRequest;
      },
      unknown
    >,
    'mutationFn'
  >
) => useMutation(({ requestBody }) => BehindTheScenesService.selectMatrixRow(requestBody), options);
export const useAuditTrailServiceFindEventsKey = 'AuditTrailServiceFindEvents';
export const useAuditTrailServiceFindEvents = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    type,
    id,
    pageNumber,
    pageSize,
  }: {
    type?: string;
    id?: string;
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof AuditTrailService.findEvents>>,
      unknown,
      Awaited<ReturnType<typeof AuditTrailService.findEvents>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useAuditTrailServiceFindEventsKey, ...(queryKey ?? [{ type, id, pageNumber, pageSize }])],
    () => AuditTrailService.findEvents(type, id, pageNumber, pageSize),
    options
  );
export const useAuditTrailServiceListAuthenticationFailuresKey = 'AuditTrailServiceListAuthenticationFailures';
export const useAuditTrailServiceListAuthenticationFailures = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof AuditTrailService.listAuthenticationFailures>>,
      unknown,
      Awaited<ReturnType<typeof AuditTrailService.listAuthenticationFailures>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useAuditTrailServiceListAuthenticationFailuresKey, ...(queryKey ?? [{ pageNumber, pageSize }])],
    () => AuditTrailService.listAuthenticationFailures(pageNumber, pageSize),
    options
  );
export const useAuditTrailServiceListCommandsKey = 'AuditTrailServiceListCommands';
export const useAuditTrailServiceListCommands = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof AuditTrailService.listCommands>>,
      unknown,
      Awaited<ReturnType<typeof AuditTrailService.listCommands>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useAuditTrailServiceListCommandsKey, ...(queryKey ?? [{ pageNumber, pageSize }])],
    () => AuditTrailService.listCommands(pageNumber, pageSize),
    options
  );
export const useAuditTrailServiceGetEventKey = 'AuditTrailServiceGetEvent';
export const useAuditTrailServiceGetEvent = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    id,
  }: {
    id: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof AuditTrailService.getEvent>>,
      unknown,
      Awaited<ReturnType<typeof AuditTrailService.getEvent>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => useQuery([useAuditTrailServiceGetEventKey, ...(queryKey ?? [{ id }])], () => AuditTrailService.getEvent(id), options);
export const useAuditTrailServiceGetBerthSafetyAuditTrailKey = 'AuditTrailServiceGetBerthSafetyAuditTrail';
export const useAuditTrailServiceGetBerthSafetyAuditTrail = <TQueryKey extends Array<unknown> = unknown[]>(
  {
    berthId,
    pageNumber,
    pageSize,
  }: {
    berthId: string;
    pageNumber?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof AuditTrailService.getBerthSafetyAuditTrail>>,
      unknown,
      Awaited<ReturnType<typeof AuditTrailService.getBerthSafetyAuditTrail>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) =>
  useQuery(
    [useAuditTrailServiceGetBerthSafetyAuditTrailKey, ...(queryKey ?? [{ berthId, pageNumber, pageSize }])],
    () => AuditTrailService.getBerthSafetyAuditTrail(berthId, pageNumber, pageSize),
    options
  );
