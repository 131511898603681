import { groupBy } from '@/utils/collections/groupBy';

/**
 * Returns an array of distinct elements based on the key, the first matching element is selected
 * @param array
 * @param key
 */
export function distinctBy<T, K extends keyof T>(array: T[], key: K): T[] {
  return array.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);
}

/**
 * Returns an array of distinct elements based on the key,
 * if there are more than 1 items match, find the first matching based on an additional condition
 * @param array
 * @param key
 * @param ifDuplicate
 */
export function distinctByKeyWithCondition<T, K extends keyof T, S extends Omit<T, K>>(array: T[], key: K, ifDuplicate: (_: S) => boolean): T[] {
  const groupByProp = groupBy(array, (item) => item[key] as string);
  return Object.entries(groupByProp).map(([, values]) => {
    if (values.length == 1) return values[0];

    return values.find((v) => {
      const { [key]: _, ...select } = v;
      return ifDuplicate(select as S);
    });
  });
}
