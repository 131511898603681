import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import { Alert, Box, Grid, Typography, styled } from '@mui/material';
import DirectionsBoatIcon from '@material-design-icons/svg/filled/directions_boat_filled.svg';
import moment from 'moment';
import { useSafetyPredictionServiceGetMooredVessels } from '@/api/ui/queries';
import { MooredVessel, SafetyPredictionType } from '@/api/ui/requests';
import { ReactComponent as MarkerSvg } from '@/assets/svg/marker.svg';
import { ReactComponent as OperationalView } from '@/assets/svg/operational.svg';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import NoScrollBox from '@/components/layout/NoScrollBox/NoScrollBox';
import { MapMarkerProps } from '@/components/maps/Map/types';
import useToBerthSafetyNavigate from '@/features/SafetyPrediction/BerthDashboard/useToBerthSafetyNavigate';
import { getAdjustedTimestampForPrediction } from '@/features/SafetyPrediction/Overview/common';
import {
  OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE,
  OVERVIEW_MAP_PREDICTION_DAYS_IN_ADVANCE,
  SAFETY_PREDICTION_TIMESTAMP_QUERY_FACTOR,
} from '@/features/SafetyPrediction/Overview/types';
import useLicence from '@/hooks/useLicence';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import { Analytics } from '@/services/analytics';
import { LicenseFeatures, SafetyPredictionTriggerStrings } from '@/types';
import classes from './index.module.css';

const StyledMarkerSvg = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.text },
  height: '12px',
}));

const BelowLowerLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.below_lower_limit.main,
  height: '20px',
}));

const DisabledIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.disabled.main,
  height: '20px',
}));

const AboveUpperLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.above_upper_limit.main,
  height: '20px',
}));

const AboveLowerLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.above_lower_limit.main,
  height: '20px',
}));

const vesselThresholdIcon = (mooredVessel: MooredVessel) => {
  let icon = <BelowLowerLimitIcon />;
  if (!mooredVessel.mostCriticalSafetyPrediction) {
    icon = <DisabledIcon />;
  } else if (mooredVessel.mostCriticalSafetyPrediction.type === SafetyPredictionType.ABOVE_LOWER_LIMIT) {
    icon = <AboveLowerLimitIcon />;
  } else if (mooredVessel.mostCriticalSafetyPrediction.type === SafetyPredictionType.ABOVE_UPPER_LIMIT) {
    icon = <AboveUpperLimitIcon />;
  } else if (mooredVessel.mostCriticalSafetyPrediction.type === SafetyPredictionType.BELOW_LOWER_LIMIT) {
    icon = <BelowLowerLimitIcon />;
  }

  return icon;
};

function popup(mooredVessel: MooredVessel) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {vesselThresholdIcon(mooredVessel)}
        &nbsp;<Typography variant={'bodySemiBoldMedium'}>{mooredVessel.vessel.name}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: '36px' }}>
        <Typography variant={'bodySemiBoldSmall'}>
          {mooredVessel.vessel.vesselType} - {mooredVessel.vessel.vesselClass}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'bodyRegularSmall'}>
          <StyledMarkerSvg />
          &nbsp;{mooredVessel.berthName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'bodyRegularSmall'}>
          Arrived at: {moment(mooredVessel.arrivalTime).tz(mooredVessel.berthTimezones?.at(0)).format('DD-MM-YYYY HH:mm z')}
        </Typography>
      </Grid>
      {mooredVessel.mostCriticalSafetyPrediction && (
        <Grid item xs={12}>
          <Typography variant={'bodySemiBoldSmall'} component={'span'}>
            Safety assessed at:&nbsp;
            {moment(mooredVessel.mostCriticalSafetyPrediction.forecast.timestamp).tz(mooredVessel.berthTimezones?.at(0)).format('DD-MM-YYYY HH:mm z')}
            <br />
          </Typography>

          <Typography variant={'bodySemiBoldSmall'} component={'span'}>
            Warning: &nbsp;{mooredVessel.mostCriticalSafetyPrediction.percentage.toFixed(2)}% of {mooredVessel.mostCriticalSafetyPrediction.label}
          </Typography>
          <br />
          {SafetyPredictionTriggerStrings[mooredVessel.mostCriticalSafetyPrediction.trigger]?.name && (
            <Typography variant={'bodySemiBoldSmall'} component={'span'}>
              Caused by: &nbsp;{SafetyPredictionTriggerStrings[mooredVessel.mostCriticalSafetyPrediction.trigger]?.name}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

const OperationalMap = lazy(() => import('@/components/maps/Map/BerthMap'));

const markerClass = (vessel: MooredVessel) => {
  if (!vessel?.dmaCaseIdentifier) return classes.inactiveMarker;
  else {
    const mostCriticalSafetyPrediction = vessel.mostCriticalSafetyPrediction;
    switch (mostCriticalSafetyPrediction?.type) {
      case SafetyPredictionType.ABOVE_LOWER_LIMIT:
        return mostCriticalSafetyPrediction?.customerDefinedType ? classes.exceededUpperCustomerMarker : classes.exceededLowerMarker;
      case SafetyPredictionType.ABOVE_UPPER_LIMIT:
        return classes.exceededUpperMarker;
      case SafetyPredictionType.BELOW_LOWER_LIMIT:
        return classes.belowLowerMarker;
      default:
        return classes.marker;
    }
  }
};

function SafetyPredictionOperationalView() {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  usePageTitle('Operational - Map', <OperationalView width={32} key={'Operational - Map'} />);
  const { hasFeature } = useLicence();
  const { navigateToBerthSafety } = useToBerthSafetyNavigate();
  const { selectedOrganisationId } = useOrganisation();
  const {
    isError,
    error,
    dataUpdatedAt,
    isLoading,
    data: vessels,
  } = useSafetyPredictionServiceGetMooredVessels(
    {
      xSelectedOrganisationId: selectedOrganisationId,
      dateTime: moment().startOf('minute').toISOString(true),
      predictionDays: OVERVIEW_MAP_PREDICTION_DAYS_IN_ADVANCE,
    },
    [selectedOrganisationId, moment().startOf('minute').toISOString(true)],
    {
      refetchInterval: 60000, // 1 minute
    }
  );

  const navigateToBerth = useCallback(
    (vessel: MooredVessel) => {
      if (!vessel?.dmaCaseIdentifier) return;

      const safetyPrediction = vessel.mostCriticalSafetyPrediction;
      const requestTimestamp = getAdjustedTimestampForPrediction(
        moment(),
        OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE * SAFETY_PREDICTION_TIMESTAMP_QUERY_FACTOR
      ).tz(vessel.berthTimezones?.at(0));

      navigateToBerthSafety({
        berthId: vessel.berthId,
        loadingCondition: safetyPrediction?.loadingCondition,
        trigger: safetyPrediction?.trigger,
        dmaCaseId: vessel.dmaCaseIdentifier,
        isOperationalVessel: true,
        vesselDisposition: safetyPrediction.vesselDisposition,
        requestTimestamp,
        days: OVERVIEW_MAP_GRAPH_DAYS_IN_ADVANCE,
        vesselMmsi: vessel.vessel.mmsi,
      });
    },
    [navigateToBerthSafety]
  );

  useEffect(() => {
    Analytics.track('Operational - Map', {});
  }, []);

  const markers: MapMarkerProps[] = useMemo(() => {
    if (!vessels) return [];
    return vessels.map((vessel) => ({
      latitude: vessel.mooredPosition.latitude,
      longitude: vessel.mooredPosition.longitude,
      markerIcon: {
        icon: DirectionsBoatIcon,
        className: markerClass(vessel),
      },
      popup: {
        content: popup(vessel),
        closeButton: false,
      },
      onMouseOver: (event) => {
        event.target.openPopup();
      },
      onMouseOut: (event) => {
        event.target.closePopup();
      },
      onClick: () => {
        navigateToBerth(vessel);
      },
    }));
  }, [vessels, navigateToBerth]);

  if (!hasFeature(LicenseFeatures.VESSEL_CLEARANCE)) {
    return;
  }

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  if (!isLoading && markers.length === 0) {
    return (
      <Box sx={{ marginX: 'auto', marginY: 10, width: '50vw' }}>
        <Alert severity="warning" color={'warning'}>
          No moored vessels found (yet).
        </Alert>
      </Box>
    );
  }
  if (vessels) {
    return (
      <NoScrollBox component="div">
        <Suspense fallback={<LoadingIndicator message={'Generating data to display vessels on the map. Please be patient...'} />}>
          <OperationalMap mapMarkers={markers} showZoomControl={true} clustering={true} />
          <div>{moment(dataUpdatedAt).format('DD-MM-YYYY HH:mm:ss')}</div>
        </Suspense>
      </NoScrollBox>
    );
  }

  return <LoadingIndicator message={'Loading map with detected vessels...'} />;
}

export default SafetyPredictionOperationalView;
